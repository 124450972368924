.border-right-left {
	border-right: none;
	border-left: none;
	@include media(">=md") {
		border-right: solid 2px $brand-color-one !important;
		border-left: solid 2px $brand-color-one !important;
	}
}

.border-right {
	border-right: none;
	@include media(">=md") {
		border-right: solid 2px $brand-color-one !important;
	}
}

.border-left {
	border-left: none;
	@include media(">=md") {
		border-left: solid 2px $brand-color-one !important;
	}
}