.btn {
	border: solid 2px $brand-color-one;
	background-color: $brand-color-one;
	text-align: center;
	padding: rem(14) rem(22);
	color: $white !important;
    font-weight: 700;
    border-radius: 0;
    text-decoration: none;
    min-width: 200px;
    margin-top: 10px;
	&:hover {
        background-color: $xdk-grey;
        color: $white;
    }
    &:after {
        font-family: $icon-font-family;
        content: '\f324';
        margin-left: 5px;
    }
    @include media(">=md") {
        min-width: 280px;
    }
}



a.button,
.button,
button,
input[type=button],
input[type=reset],
input[type=submit] {
    transition: all $transition-time $transition-easing;
    display: inline-flex;
    align-items: center;
    padding: rem(14) rem(22);
    cursor: pointer;
    font: normal bold rem(14) $primary-font;
    line-height: rem-calc(16);
    text-align: left;
    text-decoration: none;
    background-color: $color-button;
    color: $white;
    border: none;
    &:hover {
        background-color: $color-button-hover;
        color: $white;
    }
    &.alt {
        background-color: $color-button-alt;
        color: $white;
        &:hover {
            background-color: $color-button-alt-hover;
            color: $white;
        }
    }
    svg {
        &.button-leading-icon, &.button-trailing-icon {
            width: rem(12);
            height: rem(12);
            min-width: rem(10);
            min-height: rem(10);
        }
        &.button-leading-icon {
            margin-right: rem(12);
        }
        &.button-trailing-icon {
            margin-left: rem(12);
        }
    }

    // default trailing icon, the double angle,
    // is smaller than the standard icons
    // use this class to override the smaller icon
    &.button-alt-icon {
        svg {
            &.button-leading-icon, &.button-trailing-icon, &.icon {
                width: rem(18);
                height: rem(18);
            }
        }
    }
}

.button[type=reset],
input[type=reset] {
    background-color: $dk-grey;
    &:hover,
    &:active,
    &:focus {
        background-color: $grey;
    }
}
