header {
	background-color: $brand-color-one;
	&.main-header {
		// top utility navigation
		// .container {
		// 	border: solid 1px red;
		// }
		// .col, .col-auto {
		// 	border: solid 1px orange;
		// }
		.container {
			@include media("<=lg") {
				max-width: 100%;
			}
		}
		.megamenu {
			display: none;
			width: 100%;
		}
		button {
			background: transparent;
			text-transform: uppercase;
			margin-bottom: 0;
			border-top: none;
			border-right: none;
			border-bottom: transparent 5px solid;
			border-left: none;
			outline: transparent;
			cursor: pointer;
			font-size: rem(14);
			font-weight: 700;
			line-height: rem(14);
			text-align: left;
			text-decoration: none;
			color: $white;
			@include not('.hamburger','.mobile-nav--main-link'){
				&:hover, &.active, &:focus {
					color: $hilite-color;
					border-bottom-color: $hilite-color;
				}
			}
			// &:hover, &.active, &:focus {
			// 	color: $hilite-color;
			// }
			a {
				color: $white;
				&:hover {
					color: $hilite-color;
				}
			}
		}
		ul.megamenu-links {
			margin: 0;
			-webkit-box-flex: 0;
			-ms-flex: 0 1 100%;
			flex: 0 1 100%;
			-webkit-column-count: 1;
			column-count: 1;
			column-gap: rem(18);
			margin: 0;
			list-style: none;
			padding: 0;
			@include media(">=1024px") {
				-webkit-column-count: 2;
				column-count: 2;
				column-gap: rem(10);
			}
			li {
				font-size: rem(16);
				-webkit-column-break-inside: avoid;
				break-inside: avoid;
				page-break-inside: avoid;
				margin-left: 0;
				margin-top: 0;
				&:before {
					display: none;
				}
				a {
					display: block;
					color: $white;
					text-decoration: none;
					font-size: rem(16);
					font-weight: 700;
					text-transform: uppercase;
					padding: rem(10) 0 rem(10) rem(15);
					border: solid 1px transparent;
					position: relative;
					@include media(">=1024px") {
						padding: rem(5) 0 rem(5) rem(20);
					}
					&.link--has-icon {
						position: relative;
						&:before {
							position: absolute;
							top: calc(50% - 5px);
							left: 8px;
							width: 0;
							height: 0;
							content: '';
							border-top: 5px solid transparent;
							border-bottom: 5px solid transparent;
							border-left: 5px solid $white;
						}
					}
					span {
						span {
							display: block;
							font-size: rem(18);
						}
					}
					&:hover, &:active, &:focus {
						text-decoration: none;
						color: $hilite-color;
						border: solid 1px $hilite-color;
						&:before {
							border-left: 5px solid $hilite-color;
						}
					}
				}	
				ul {
					li {
						a {
							font-size: rem(14);
							padding: rem(5) 0 rem(5) rem(35);
							&.link--has-icon {
								&:before {
									left: 20px;
								}
							}
						}
					}
				}
			}
		}
		.utility-nav, .main-nav {
			button {
				svg {
					margin-left: rem(2);
					width: rem(12);
					height: rem(12);
					transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
				}
				&.active {
					svg {
						transform: rotate(90deg);
					}
				}
			}
		}
		.utility-nav {
			flex-basis: rem(30);
			.utility-menus {
				display: flex;
				@include media("<=1024px") {
					justify-content: space-between!important;
					button {
						padding-top: rem(15);
						padding-bottom: rem(15);
					}
				}
				button {
					margin-right: rem(20);
					// padding: rem(20) 0;
					&:last-child {
						margin-right: 0;
					}
				}
			}
			.utility-contact-search {
				display: none;
				@include media(">=1024px") {
					display: block;
					button {
						padding: rem(10) 0;
						&.toggleSearch {
							svg {
								width: rem(20);
								height: rem(20);
							}
						}
					}
					a {
						color: $white;
						margin-right: rem(20);
						font-size: rem(14);
					}
				}	
			}
			.megamenu-utility, .megamenu-utility-search {
				background-color: lighten($brand-color-one, 10%);
			}
			.megamenu-utility {
				padding: rem(30);
			}
			.megamenu-utility-search {
				padding: rem(30);
				.search-bar {
					.search-bar-form {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						input[type="text"] {
							display: inline-flex;
							-webkit-box-flex: 1;
							-ms-flex: 1 1 auto;
							flex: 1 1 auto;
							margin-bottom: 0;
							-webkit-transition: none;
							transition: none;
							padding: 10px;
						}
						button[type="submit"]  {
							-webkit-transition: all 0.2s ease-out;
							transition: all 0.2s ease-out;
							display: -webkit-inline-box;
							display: -ms-inline-flexbox;
							display: inline-flex;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
							padding: 0.875rem 1.375rem;
							cursor: pointer;
							font: normal bold 0.875rem "Open Sans", sans-serif;
							line-height: 1rem;
							text-align: left;
							text-decoration: none;
							background-color: lighten($brand-color-one, 20%);
							color: #fff;
							svg {
								width: rem(20);
								height: rem(20);
							}	
					   }
					}
				}
			}
		}
		// main nav header wrapper
		.main-header-wrapper {
			// &:before {
			// 	content: '';
			// 	display: block;
			// 	width: 100%;
			// 	height: 2px;
			// 	background: lighten($brand-color-one, 10%);
			// }
				a.logo {
					display: inline-block;
					width:  rem(200);
					margin: 0;
					padding: rem(10) 0;
					margin: 0;
					@include media(">=xs") {
						width:  rem(240);
					}
					@include media(">=1110px") {
						width:  rem(300);
						margin: rem(10) 0;
						border: solid 2px transparent;
						&:focus {
							border: solid 2px $hilite-color;
						}
					}
					img {
						width: 100%;
						height: auto;
						display: inline-block;
						padding: 5px;
					}
				}
			
			.main-nav-mobile-toggle {
				display: block;
				position: absolute;
				top: 0px;
				right: 0;
				@include media(">=1024px") {
					display: none;
				}
			}
			.main-nav-links {
				display: none;
				@include media(">=1024px") {
					display: flex;
					height: 100%;
				}
				button {
					// padding: rem(40) 0;
					padding: 0;
					margin: 0 rem(18) 0 0;
					font-size: rem(16);
					&.toggleSearch {
						svg {
							width: rem(20);
							height: rem(20);
						}
					}
					&:last-child {
						margin: 0;
					}
				}
			}
		}
		.main-header-megamenus {
			// background-color: lighten($brand-color-one, 10%);
			background-color: $brand-color-two;
			// display: none;
			// @include media(">=1024px") {
			// 	display: block;
			// }
			.megamenu {
				padding: 0;
				@include media(">1024px") {
					padding: rem(30) 0;
				}
				.megamenu-wrapper {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-pack: justify;
					-ms-flex-pack: justify;
					justify-content: space-between;
					-ms-flex-line-pack: start;
					align-content: flex-start;
					margin-bottom: 0;
					.megamenu-heading {
						display: none;
						@include media(">1024px") {
							display: block;
							-webkit-box-flex: 0;
							-ms-flex: 0 1 24%;
							flex: 0 1 24%;
							// margin-top: rem(10);
						}
						.megamenu-title {
							text-decoration: none;
							display: inline-block;
							display: -webkit-inline-box;
							display: -ms-inline-flexbox;
							display: inline-flex;
							text-transform: uppercase;
							background-color: transparent;
							font-family: $condensed-font;
							font-size: rem(40);
							line-height: rem(40);
							padding: 0 0 rem(10) 0;
							color: $white;
							border-bottom-color: transparent;
							a {
								text-decoration: underline;
								&:hover, &:active, &:focus {
									text-decoration: none;
									color: $hilite-color;
									text-decoration: underline;
								}
							}
						}
					}
					.megamenu-content {
						-webkit-box-flex: 0;
						-ms-flex: 0 1 100%;
						flex: 0 1 100%;
						@include media(">1024px") {
							-ms-flex: 0 1 74%;
							flex: 0 1 74%;
						}
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-pack: justify;
						-ms-flex-pack: justify;
						justify-content: space-between;
					}
					.search-bar {
						width: 100%;
						.search-bar-form {
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							input[type="text"] {
								display: inline-flex;	
								-webkit-box-flex: 1;
								-ms-flex: 1 1 auto;
								flex: 1 1 auto;
								margin-bottom: 0;
								-webkit-transition: none;
								transition: none;
								padding: 10px;
							}
							button[type="submit"]  {
								-webkit-transition: all 0.2s ease-out;
								transition: all 0.2s ease-out;
								display: -webkit-inline-box;
								display: -ms-inline-flexbox;
								display: inline-flex;
								-webkit-box-align: center;
								-ms-flex-align: center;
								align-items: center;
								padding: 0.875rem 1.375rem;
								cursor: pointer;
								font: normal bold 0.875rem "Open Sans", sans-serif;
								line-height: 1rem;
								text-align: left;
								text-decoration: none;
								background-color: $grey;
								color: #fff;
								svg {
									width: rem(20);
									height: rem(20);
								}	
						   }
						}
					}
				}
			}
		}
		.mobile-nav {
			@include media(">=1024px") {
				display: none;
			}
			ul {
				margin: 0;
				list-style: none;
				padding: 0;
				> li {
					margin-bottom: 0;
					display: block;
					border-top: rgba($white, .2) rem(2) solid;
					font-size: 1rem;
					font-weight: bold;
					margin-top: 0;
					margin-left: 0;
					&:before {
						display: none;
					}
					&:first-child {
						border-top: none;
					}
					button {
						padding: rem(14) rem(18);
						width: 100%;
						font-size: 18px;
						svg {
							width: rem(16);
							height: rem(16);
							margin-left: rem(10);
							transition: transform .2s ease-out,
							-webkit-transform .2s ease-out;
						}
						&.active {
							border-bottom-color: transparent;
							color: $hilite-color;
							svg {
								transform: rotate(90deg);
							}	
						}
					}
				}
			}
			.search-bar {
				padding: rem(20);
				background-color: $grey;
				.search-bar-form {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					input[type="text"] {
						display: inline-flex;
						-webkit-box-flex: 1;
						-ms-flex: 1 1 auto;
						flex: 1 1 auto;
						margin-bottom: 0;
						-webkit-transition: none;
						transition: none;
						padding: 10px;
					}
					button[type="submit"]  {
						-webkit-transition: all 0.2s ease-out;
						transition: all 0.2s ease-out;
						display: -webkit-inline-box;
						display: -ms-inline-flexbox;
						display: inline-flex;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						padding: 0.875rem 1.375rem;
						cursor: pointer;
						font: normal bold 0.875rem "Open Sans", sans-serif;
						line-height: 1rem;
						text-align: left;
						text-decoration: none;
						background-color: $brand-color-two;
						color: #fff;
						svg {
							width: rem(20);
							height: rem(20);
						}	
				   }
				}
			}
		}
	}
}

header.main-header .main-nav-mobile-toggle {
    $icon-size: rem(22);
	$bar-size: rem(3);
	height: 100%;
	color: $white;
    span.hamburger-icon {
        margin-left: rem(10);
        display: block;
        position: relative;
        width: $icon-size;
        height: $icon-size;
        transition: 0.2s ease-out;
        span {
            display: block;
            position: absolute;
            height: $bar-size;
            width: 100%;
            background: #fff;
            border-radius: rem(9);
            opacity: 1;
            left: 0;
            transition: .25s ease-in-out;
            &:nth-child(1) {
                top: 0;
            }
            &:nth-child(2),
            &:nth-child(3) {
                // top: rem(15);
                top: rem(9);
            }
            &:nth-child(4) {
                bottom: 0;
            }
        }
    }
    .active {
		color: $hilite-color;
        span.hamburger-icon {
            span {
				background: $hilite-color;
                &:nth-child(1),
                &:nth-child(4) {
                    top: ($icon-size/2) - ($bar-size/2);
                    width: 0%;
                    left: 50%;
                }
                &:nth-child(2) {
                    transform: rotate(45deg);
                }
                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
            }
        }
    }
    button {
		display: inline-flex;
		height: 100%;
		font-size: rem(16);
		&.active {
			border-bottom-color: transparent;
		}
    }
}
