.slab {	
	&.featured-slab {	
		background-position: center;
		background-repeat: no-repeat;
		background-blend-mode: overlay;
		background-color: #500000;
		background-size: cover;
		position: relative;
		display: block;
		.inner {
			background: rgba(80,0,0,0.8);
			padding: rem(80) 0;
			a {
				border: solid 2px $white;
				padding: rem(30);
				margin: rem(20) 0;
				display: block;
				color: $white;
				position: relative;
				overflow: hidden;
				z-index: 1;
				text-transform: uppercase;
				font-size: rem(16);
				@include media(">=md") {
					font-size: rem(20);
				}
				&:before {
					position: absolute;
					display: block;
					top: calc(50% - 50px);
					transform: translateX(-10px) translateX(-100%);
					content: url(../images/bg-arrow-white.svg);
					width: 1400px;
					z-index: -1;
					transition: all .5s ease;
				}
				&:hover,  &:active, &:focus {
					color: $brand-color-one;
					text-decoration: none;
					&:before {
						// left: calc(100% - 1400px);
						transform: translateX(-100%) translateX(50%);
					}
				}
				&:focus {
					outline: 2px dashed $focus-color;
				}
			}
		}
	}
	&.announcements {
		padding: rem(60) 0;
		background-color: $white;
		ul {
			column-count: 1;
			margin-top: rem(20);
			padding: 0;
			display: block;
			@include media(">=sm") {
				column-count: 3;
			}
			> li {
				text-align: left;
				display: block;
				page-break-inside: avoid;
				break-inside: avoid;
				margin-bottom: rem(20);
				// padding-left: rem(20);
				position: relative;
				&:before {
					font-family: $icon-font-family;
					content: "\f324";
					position: absolute;
					font-weight: 700;
					left: 0;
					top: 0;
					color: $brand-color-one;
				}
			}
		}
	}
	&.important-dates {
		padding: rem(60) 0;
		background-color: $xlt-grey;
		.section-header-title {
			h2 {
				background-color: $xlt-grey;
			}
		}
		ul {
			column-count: 1;
			margin-top: rem(20);
			padding: 0;
			display: block;
			@include media(">=sm") {
				column-count: 3;
			}
			> li {
				text-align: left;
				display: block;
				page-break-inside: avoid;
				break-inside: avoid;
				margin-bottom: rem(30);
				position: relative;
				&:before {
					display: none;
				}
				.date {
					font-family: $condensed-font;
					font-size: rem(28);
					font-weight: 700;
				}
				.title {
					font-size: rem(18);
					font-weight: 700;
				}
				.description {
					font-size: rem(16);
					font-weight: 400;
				}
			}
		}
	}
	&.hours {
		padding: rem(60) 0;
		background-color: $xlt-grey;
		.section-header-title {
			h2 {
				background-color: $xlt-grey;
			}
		}
	}
	&.grey {
		padding: rem(60) 0;
		background-color: $xlt-grey;
		.section-header-title {
			h2 {
				background-color: $xlt-grey;
			}
		}
	}
	&.white {
		padding: rem(60) 0;
		background-color: $white;
		.section-header-title {
			h2 {
				background-color: $white;
			}
		}
	}
	&.questions {
		*[class^='col-'] {
			padding-bottom: rem(55);
			.btn {
				min-width: rem(220);
				position: absolute;
				bottom: 0;
				left: calc(50% - 6.875rem);
			}
		}
	}
}


.button-padding {
	padding-bottom: rem(60);
}



.feature-block {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    background: #fff;
    clear: both;
    display: block;
    .teaser {
        display: flex;
        position: static;
        justify-content: flex-start;
        // max-width: 75rem;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 2;
        overflow: hidden;
        flex-flow: row wrap;
        @include media(">=xs") {
            min-height: rem(200);
        }
        @include media(">=sm") {
            justify-content: flex-end;
            min-height: rem(380);
        }
        @include media(">=lg") {
            min-height: rem(500);
        }
        .teaser-image {
            width: 100%;
            position: static;
            max-width: none;
            content: '';
            display: block;
            margin: 0;
            z-index: 0;
			order: 1;
			max-height: 250px;
			display: flex;
			align-items: center;
			overflow: hidden;
            @include media (">=sm") {
                max-width: 50%;
                position: absolute;
                top: 0;
                bottom: 0;
				left: 0;
				max-height: none;
            }
            img {
                width: 100%;
                height: auto;
                display: inline-block;
                vertical-align: middle;
                max-width: 100%;
            }
        }
        .teaser-body {
            flex: 0 1 100%;
            position: relative;
            padding-top: rem(20);
            padding-bottom: rem(20);
            padding-left: rem(20);
            @include media(">=xs") {
                flex: 0 1 50%;
                padding-top: rem(10);
                padding-left: rem(40);
            }
            @include media(">=sm") {
                padding-top: rem(20);
				padding-left: rem(60);
				padding-right: rem(60);
			}
			@include media(">=lg") {
                padding-top: rem(100);
            }
            .headline {
                font-size: rem(38);
				font-weight: 700;
				font-family: $header-font;
            }
            .text {

            }
        }
    }
    &.alt {
        .teaser {
            justify-content: flex-start;
            .teaser-image {
                order: 2;
                left: auto;
                right: 0
            }
            .teaser-body {
                order: 1;
            }
        }
    }
}