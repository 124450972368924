ul {
    list-style: none;
    // margin: 0 0 20px 30px;
    padding: 0;
    li {
        // margin-top: 10px;
        margin-left: 25px;
        &:before {
            font-family: $icon-font-family;
            content: "\f061";
            padding-right: 10px;
            margin-left: -25px;
        }
    }
    ul {
        list-style: none;
        ul {
            list-style: none;
            ul {
            list-style: none;
                ul {
                list-style: none;
                }
            }
        }
    }
}

ul {
    &.check {
        li {
            &:before {
                font-family: $icon-font-family;
                content: "\f00c";
                padding-right: 10px;
                margin-left: -25px;
            }
        }
    }
    &.link {
        li {
            &:before {
                font-family: $icon-font-family;
                content: "\f0c1";
                padding-right: 10px;
                margin-left: -25px;
            }
        }
    }
}

ul {
    li {
        &.link {
            &:before {
                font-family: $icon-font-family;
                content: "\f0c1";
                padding-right: 10px;
                margin-left: -25px;
            }
        }
    }
}

// ul.none {
//  list-style: none;
//  margin: 0;
//  padding: 0;
// }

ol {
    li {
        margin-bottom: 10px;
        ol {
            li {
                margin-bottom: 10px;
            }
        }
        ul {
            margin-left: 25px;
            li {
                margin-bottom: 10px;
            }
        }
    }
}

ul {
    &.two-up {
        @include media(">=sm") {
            column-count: 2;
        }
        li {
            @include media(">=sm") {
                display: block;
                page-break-inside: avoid;
                break-inside: avoid;
            }
        }
    }
    &.three-up {
        @include media(">=sm") {
            column-count: 3;
        }
        li {
            @include media(">=sm") {
                display: block;
                page-break-inside: avoid;
                break-inside: avoid;
            }
        }
    }
    &.four-up {
        @include media(">=sm") {
            column-count: 3;
        }
        @include media(">=md") {
            column-count: 3;
        }
        @include media(">=lg") {
            column-count: 4;
        }
        li {
            @include media(">=sm") {
                display: block;
                page-break-inside: avoid;
                break-inside: avoid;
            }
        }
    }
    &.native {
        li {
            a {
            text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}