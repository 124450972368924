/*--------------------------------------------------------------
# Page Header
--------------------------------------------------------------*/


.header {
	position: relative;
	font-family: $primary-font;
	background-image: none;
	height: auto;
	z-index: 2000;
	background-color: $brand-color-one;
    color: #fff;
	box-shadow: none;
	@include media(">=md") {
		// @include rem('height',105px);
		background-color: $brand-color-one;
		// height: rem(108);
		height: rem(85);
		// box-shadow: 0 4px 4px -4px $black;
	}
	.mob {
		display: block;
		padding-top: 20px;
		width: 68px;
		height: 50px;
		float: right;
		@include media(">=md") {
			display: none;
		}
		.fa {
			font-size: 30px;
			color: $brand-color-two;
		}
	}
}	


