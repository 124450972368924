.top-nav-wrap {
	position: relative;
	z-index: 100;
	background-color: $top-nav-color-one;
	@include media(">=md") {
		// background: $brand-color-one;
		// background-color: $top-nav-color-one;
		height: rem(44);
		position: absolute;
		// top: 16px;
		top: 0;
		width: 100%;
		// background-image: url(../images/bg-navigation.png);
		// height: 40px;
		// background-position: -90px 0;
		@include media(">=1080px") {
			// background-position: top left;
			padding-top: rem(8);
		}
	}
	.container-fluid {
		width: 100%;
		padding-right: 0;
		padding-left: 0;
		@include media(">=md") {
			padding-right: 15px;
			padding-left: 15px;
		}
	}
	ul.top-nav {
		@include reset-list;
		list-style: none;
		padding: 0;
		text-align: left;
		margin: 0;
		@include media(">=md") {
			text-align: right;
			padding: rem(7) 0 0 0;
		}
		li {
			@include reset-list;
			display: block;
			position: relative;
			@include media (">=md") {
				display: inline-block;
				margin-left: rem(20);
			}
			&.search-toggle {
				display: none;
				@include media(">=md") {
					display: inline;
				}
				a {
					position: relative;
					padding: rem(5) rem(15);
					&:before {
						font-family: $icon-font-family;
						content: "\f002";
						font-size: rem(16);
						position: absolute;
						top: rem(3);
						left: rem(6);
					}
				}
			}
			a {
				padding: 15px;
				text-transform: none;
				color: $white;
				font-size: 20px;
				border-top: solid 1px $top-nav-border-color-mob;
				display: block;
				// height: 62px;
				&:hover {
					text-decoration: none;
				}
				@include media(">=md") {
					display: inline;
					height: auto;
					font-size: rem(16);
					border-top: solid 1px $top-nav-border-color-one;
					border-right: solid 1px $top-nav-border-color-one;
					border-bottom: solid 1px $top-nav-border-color-one;
					border-left: solid 1px $top-nav-border-color-one;
					// text-transform: uppercase;
					padding: rem(5);
					&:hover, &:focus {
						text-decoration: none;
						border-top: solid 1px $white;
						border-right: solid 1px $white;
						border-bottom: solid 1px $white;
						border-left: solid 1px $white;
					}
					// &:focus {
					// 	background-color: $black;
					// 	color: $white;
					// }
				}
			}
		}
		@include media(">=md") {
			>li+li:before {
				// padding: 0 5px;
				// color: $white;
				// content: " | ";
			}
		}	
	}
}