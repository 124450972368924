footer {
	padding: rem(60) 0;
	background-color: $brand-color-one;
	color: #adabab;
	.logo {
		width: 280px;
		display: block;
		margin: 0 auto;
		margin-bottom: rem(20);
		@include media(">=md") {
			width: 100%;
			margin-bottom: 0;
		}
	}
	.links {
		text-align: center;
		@include media(">=sm") {
			text-align: right;		
		}
		ul.footer-links {
			position: relative;
			list-style: none;
			text-align: left;
			margin: 0;
			padding: 0;
			display: inline;
			li {
				padding-right: rem(10);
				text-align: left;
				display: inline-block;
				&:before {
					display: none;
				}
				&:last-child {
					padding-right: 0;
				}
				@include media(">=sm") {
						
				}
				a {
					display: block;
					padding: rem(3) rem(15) rem(3) 0;
					font-size: rem(12);
					color: $white;
					text-decoration: none;
					font-weight: 700;
					&:last-child {
						padding: rem(3) 0;
					}
					&:hover {
						color: $white;
						text-decoration: underline !important;
					}
					@include media(">=sm") {
						
					}
				}
			}
		}
	}
	.copyright {
		border-top: dotted 1px $grey;
		margin-top: rem(20);
		padding-top :rem(20);
		font-size: rem(12);
		a {
			color: $white;
			&:hover {
				color: $white;
				text-decoration: underline;	
			}
		}
	}
}
	