body {
	background-color: $brand-color-one;
}

html {
	font-size: 100% ;
}
.pagetitle {
	background-color: $brand-color-one-alt; 
	background-position: 50% 50%,100% 20%;
    background-size: contain,cover;
	margin-top: 0;
	padding: rem(15) 0;
	h1 {
		margin: 0;
		padding: 0;
		color: $white;
		font-family: $condensed-font;
		font-size: rem(30);
		line-height: rem(30);	}
}

.pagecontent {
	background-color: $white;
	padding: 0;
}

p {
	&.bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}