img {
	max-width: 100%;
}

img.right {
	float: right;
	margin: 0 0 px(10) px(20);
}

img.left {
	float: right;
	margin: 0 px(20) px(10) 0;
}

.hdr-image {
	max-width: 100%;
	margin-bottom: px(40);
}

.image-wrapper {
	font-size: px(12);
	line-height: px(22);
	margin: 0 0 px(20) px(20);
	@include media(">=sm") {
		width: px(400);
	}
	.image-container {
		padding: px(10);
		border: 1px solid $dk-grey;
		margin-bottom: px(20);
		img {
			width: 100%;
		}
	}
	&.right {
		float: right;
	}
	&.left {
		float: left;
	}
}