/* PROJECT COLORS */
$brand-color-one: #500000;
$brand-color-one-alt: #6A2626;
// $brand-color-two: #003c71;
$brand-color-two: #333438;
$brand-color-three: #493064;
$brand-color-four: #b7a99a;
$brand-color-cta: #ffffff;
$hilite-color: yellow;
$focus-color: yellow;
$text-color: #3d3935;
$color-alert: $brand-color-two;
$color-alert-emergency: $brand-color-one-alt;
$link-color: #343e97;
$link-color-hover: $brand-color-one;
$error-color: red !default;
$success-color: blue !default;
$white: #fff;
$black: #000;
$rgb-white: "255,255,255" !default;
$rgb-black: "0,0,0" !default;
$xlt-grey: #f3f3f3;
$lt-grey: #cfcfcf;
$grey: #707373;
$dk-grey: #332c2c;
$xdk-grey: #252525;


$color-button: $brand-color-two;
$color-button-hover: $white;
$color-button-alt: $brand-color-one;
$color-button-alt-hover: $brand-color-two;

// Standard Nav Colors
$nav-color-one: #334b94;
$nav-color-hover-one: rgba(0,0,0,.5);
$nav-color-two: $brand-color-two;
$nav-color-three: purple;
$nav-color-four: green;

// Nav Colors
$primary-button-color:$white;
$primary-button-color-hover: #CCC;
$nav-primary: $brand-color-one;
$nav-bg: $white;
$breadcrumb-color: #CCC;
$breadcrumb-bg: #ac1f27;

// Mega Menu Main Nav Colors
$mega-bg-one: $brand-color-two;
$mega-bg-two: green;
$mega-link-bg-hover: rgba(0,0,0,.5);
$mega-link-color: rgba(255,255,255,.95);
$mega-link-color-hover: $white;
$mega-link-border-color: rgba(255,255,255,.75);
$mega-link-border-color-hover: $white;

// Mega Menu Mobile Nav Colors
$mega-mob-color-one: $brand-color-one;
$mega-mob-color-two: darken($brand-color-one, 5%);
$mega-mob-link-color-one: $white;
$mega-mob-border-color: $white;

// Top Nav Colors
$top-nav-color-one: $brand-color-one;
$top-nav-border-color-one: $brand-color-one;
$top-nav-border-color-one-hover: $white;
$top-nav-border-color-mob: $white;

// First Level Subnav Colors
$subnav-border-color: #777;
$subnav-color: #777;
$subnav-bg-color: $white;
$subnav-color-hover: $white;
$subnav-bg-color-hover: #777;

// Second Level Subnav Colors
$subnav-color2: $white;
$subnav-bg-color2: #424242;
$subnav-color-hover2: $white;
$subnav-bg-color-hover2: $white;

// Toggle Sub
$toggle-sub-color-one: $mega-mob-color-two;
$toggle-sub-height: 69px;
$toggle-sub-width: 68px;

/* Fonts */
$primary-font: 'Open Sans', sans-serif !default;
$header-font:  "Tungsten A", "Tungsten B", sans-serif !default;
$condensed-font: "Tungsten A", "Tungsten B", sans-serif !default;
$serif-font: 'Open Sans', sans-serif!default;
$sans-serif-font: 'Open Sans', sans-serif !default;
$icon-font-family: 'Font Awesome 5 Pro';
$icon-font-family-brands: 'Font Awesome 5 Brands';
$icon-font-family-alt: 'Material Icons';

/* Font Sizes */
$font-xs: 12px;
$font-sm: 14px;
$font-lg: 18px;
$font-xl: 20px;

$transition-time: .2s;
$transition-easing: ease-out;


