.alert {
    background: $xlt-grey;
    border: #fff 2px solid;
    border-left: 0;
    border-right: 0;
    padding: 0;
    border-radius: 0;
	.container {
		@include media("<=lg") {
			max-width: 100%;
		}
	}
    .alert-wrapper {
        // @include basic-contain;
        display: flex;
        flex-flow: column nowrap;
        @include media(">=sm") {
            flex-flow: row nowrap;
        }
        .alert-icon-wrapper {
            background: $color-alert;
            padding: rem(10) rem(15);
            display: flex;
            justify-content: center;
            align-items: center;
            @include media(">=sm") {
                justify-content: flex-start;
                padding: rem(30);
            }
            svg {
                color: #fff;
                width: rem(25);
                height: rem(25);
                @include media(">=sm") {
                    width: rem(40);
                    height: rem(40);
                }
            }
        }
        .alert-message {
            padding: rem(10);
            @include media(">=sm") {
                padding: rem(20);
            }
            .alert-headline {
				font-family: $condensed-font;
				color: $brand-color-two;
				font-weight: 700;
				font-size: rem(26);
				text-transform: uppercase;
                margin: 0;
            }
            .alert-detail {
                font-size: rem(16);
				margin-bottom: 0;
				a {
					position: relative;
					font-weight: 700;
					&:after {
						font-family: $icon-font-family;
						content: '\f324';
						position: absolute;
						bottom: 0;
						right: -18px;
						font-weight: 700;
						font-size: rem(14);
					}
				}
            }
        }
    }

    &.alert-emergency {
        .alert-icon-wrapper {
            background: $color-alert-emergency;
        }
    }
}

// special styles for alerts within slabs
.slab {
    .alert {
        margin-bottom: 0;
    }
}
