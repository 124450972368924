.accordion-wrapper {
	text-align: left;
}
.accordion-container {
width: 100%;
clear: both;
position: relative;
}

a.accordion-toggle {
	position: relative;
	display: block;
	margin: 5px 5px 10px 0;
	padding: 0;
	font-size: 20px;
	font-weight: 300;
	color: $dk-grey;
	text-decoration: none;
	.open{
		color:  $dk-grey;
	}
	&:visited {
		color: $dk-grey;
	}
	&:hover, &:active {
		color:$black;
	}
	&:after {
		content: '';
		position: absolute;
		left: 0; 
		bottom: rem(8);
		height: 1px;
		background-color: $brand-color-one;
		width: 100%;
	}
	.element-title {
		display: inline-block;
		color: $brand-color-one;
		text-transform: uppercase;
		font-weight: 700;
		font-size: rem(14);
		line-height: rem(14);
		position: relative;
		background-color: $white;
		z-index: 2;
		padding-right: 10px;
		@include media(">=sm") {
			font-size: rem(16);
			line-height: rem(16);
		}
	}
}
.accordion-toggle span.toggle-icon {
	position: absolute;
	top: 0;
	right:0;
	color: $brand-color-one;
	padding: 3px;
	background-color: $white;
	z-index: 2;
}
.accordion-content {
	position: relative;
	display: none;
	padding: 0 0 rem(30) 0;
	overflow: auto;
	transition: none;
	border: none;
}


/* media query for mobile */
// @media (max-width: 767px) {
// 	.content {
// 		width: auto;
// 	}
// 	.accordion-content {
// 		padding: 10px 0;
// 		overflow: inherit;
// 	}
// }
