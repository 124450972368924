.icon-links {
	padding: rem(60) 0;
	background-color: $white;
	a {
		border: solid 1px #bcbcbc;
		display: inline-block;
		text-align: center;
		padding: 5px;
		margin: 5px;
		border-radius: 20px;
		// box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.20);
		@include media(">=sm") {
			padding: 10px;
			margin: 10px;
			box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.20);
		}
		@include media(">=md") {
			padding: 20px;
			margin: 20px;
			box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.20);
		}
		.link-title {
			display: block;
			font-size: rem(12);
			font-weight: 400;
			font-family: $condensed-font;	
			margin-top: -10px;
			@include media(">=sm") {
				font-size: rem(20);
			}
		}
		&:hover {
			box-shadow: inset 0px 0px 10px 5px rgba(0,0,0,0.20);
		}
	}
	&.alt {
		a {
			border: none;
			box-shadow: none;
		}
	}
}
