@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Open+Sans:300,400,700');

body {
   @include font-setting($primary-font, normal, 400);
    color: $text-color;
	text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
	font-size: 1rem;
}

h1, h2, h3, h4, h5, h6 {
	@include font-setting($header-font, normal, 400);
	color: $brand-color-one;
	text-transform: uppercase;
	letter-spacing: 2px;
}

h1 {
	font-size: rem(38);
}

h2 {
	font-size: rem(32);
}

h3 {
	font-size: rem(26);
}

h4 {
	font-size: rem(20);
}

h5 {
	font-size: rem(18);
}

h6 {
	font-size: rem(16);
}

.section-header-title {
	border-bottom: 0;
	text-align: center;
	height: 50px;
	position: relative;
	&:before {
		display: block;
		border-bottom: 2px solid #cccccc;
		content: "";
		width: 100%;
		position: absolute;
		top: 18px;
		z-index: 0;
	}
	h1{
		display: inline-block;
		z-index: 2;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		background: #fff;
		padding: 0 rem(5);
		font-size: rem(26);
		@include media(">=sm") {
			font-size: rem(38);
		}
	}
	h2{
		display: inline-block;
		z-index: 2;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		background: #fff;
		padding: 0 rem(5);
		font-size: rem(24);
		@include media(">=sm") {
			font-size: rem(32);
		}
	}
}

//Links

a, a:visited {
	color:$link-color;
	text-decoration: underline;
	transition: all 0.2s ease-out;
	font-weight: 700;
	&:hover, &:active, &:focus {
		color:$link-color-hover;
		text-decoration: none;
		outline: 0;
		text-decoration: underline;
	}
}

p.dropcap:first-child:first-letter {
	color: #903;
	float: left;
	font-family: Georgia;
	font-size: rem(75);
	line-height: rem(60);
	padding: rem(4) rem(8) rem(3) 0;
}

button  {
	outline: 0;
}

hr {
	margin: rem(40) 0;
    border: 0;
    border-top: 1px dotted $dk-grey;
	&.white {
		border-top: 1px dotted $white;
	}
}

.screen-reader-text {
    left: -9999em;
    position: absolute;
    top: -9999em;
}

.smalltext {
	font-size: rem(12);
}

hr {
	font-weight: 700;
}

a[href$=".pdf"] {
    &:after {
        font-family: $icon-font-family;
        content: '\f1c1';
        margin-left: rem(5);
        margin-right: rem(5);
    }
}
a[href$=".doc"], a[href$=".docx"] {
    &:after {
        font-family: $icon-font-family;
        content: '\f1c2';
        margin-left: rem(5);
        margin-right: rem(5);
    }
}
a[href$=".xls"], a[href$=".xlsx"] {
    &:after {
        font-family: $icon-font-family;
        content: '\f1c3';
        margin-left: rem(5);
        margin-right: rem(5);
    }
}