.container {
    @include media(">=sm") {
        max-width: rem(720);
    }
    @include media(">=md") {
        max-width: rem(960);
    }
    @include media(">=lg") {
        max-width: rem(1140);
    }
    @include media(">=xl") {
        max-width: rem(1400);
    }
}

// Bootstrap Container Breakpoint Overrides
// .container {
//     @include media(">=md") {
//         max-width: rem(1140px);
//     }
// }