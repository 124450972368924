.bc {
	background-color: $grey;
	padding: rem(10) 0;
}

.breadcrumbs {
    margin: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
	padding: 0;
	display: block;
	text-align: left;
	margin-top: rem(-5);
	li {
		display: inline-block;
		margin: 0;
		padding: 0;
		margin-top: 0;
		&:before {
			display: none;
		}
		a, a:visited {
			text-transform: uppercase;
			text-decoration: none;
			font-size: rem(12);
			line-height: rem(12);
			color:$white;
			padding: 0;
			&:hover, &:active {
				color:$xlt-grey;
				text-decoration: underline;
			}
		}
	}
	>li+li {
		margin-left: rem(20);
		&:before {
			display: inline;
			padding: 0 5px;
			font-family: $icon-font-family;
			font-size: rem(10);
			font-weight: 300;
			color: $white;
			content: " \f061 ";
		}
	}
}

