.space-above {
	margin-top: rem(20) !important;
}

.space-above-lg {
	margin-top: rem(40) !important;
}

.space-below {
	margin-bottom: rem(20) !important;
}

.space-below-lg {
    margin-bottom: rem(40) !important;
}

.padding-below {
    padding-bottom: rem(20) !important;
}

.padding-below-lg {
    padding-bottom: rem(40) !important;
}