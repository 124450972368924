//Not 

@mixin not($ignorList...) {
    //if only a single value given
    @if (length($ignorList) == 1){
        //it is probably a list variable so set ignore list to the variable
        $ignorList: nth($ignorList,1);
    }
    //set up an empty $notOutput variable
    $notOutput: '';
    //for each item in the list
    @each $not in $ignorList {
        //generate a :not([ignored_item]) segment for each item in the ignore list and put them back to back
        $notOutput: $notOutput + ':not(#{$not})';
    }
    //output the full :not() rule including all ignored items
    &#{$notOutput} {
        @content;
    }
}


// Center Block
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Display Block
@mixin block {
    display: block;
}

// Font Setting
@mixin font-setting($family, $style, $weight) {
    font-family: $family;
    font-style: $style;
    font-weight: $weight;
}
// Typeset
@mixin typeset($size) {
    font-size: px($size);
    //line-height: leading($leading, $size);
}

// Reset List
@mixin reset-list {
    list-style: none;
    padding-left: 0;
}

// Truncate Text
@mixin truncate-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

// Truncate Text Scroll
@mixin truncate-text-scroll {
    display: block;
    overflow: scroll;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

@mixin media($conditions...) {
  @for $i from 1 through length($conditions) {
    $conditions: set-nth($conditions, $i, parse-expression(nth($conditions, $i)));
  }

  $branches: get-query-branches($conditions);
  $query: '';

  @each $branch in $branches {
    @if (str-length($query) != 0) {
      $query: $query + ', ';
    }

    $query: $query + $branch;
  }

  @media #{$query} {
    @content;
  }
}